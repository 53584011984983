import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentContainer } from "../components/Theme"
import ParallaxBackground from "../components/ParallaxBackground"

const PagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 15px;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`

const PagesContainer = styled.div`
  color: black;
  box-shadow: none;
  margin: 5px 5px 5px 0;
  width: 270px;
  height: 140px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  h3 {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    margin: 0 0 5px 0;
  }
  p {
    font-size: 14px;
    margin: 0;
  }

  &:hover {
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  }
`
export default function pages({ data }) {
  const pagesNode = data?.allContentfulPagesPage?.edges[0]?.node
  const heroImage = pagesNode.heroImage.file.url

  const {
    title,
    memoryPathPartOneTitle,
    theMemoryGateDescription,
    // memoryPathPartThreeTitle,
    // memoryPathPartThreeDescription,
    poetryTitle,
    poetryDescription,
    publicationsTitle,
    publicationsDescription,
    thoughtsTitle,
    thoughtsDescription,
    grandHotelFantasiaTitle,
    grandHotelFantasiaDescription,
  } = pagesNode

  return (
    <Layout>
      <SEO title="Pages" />
      <ParallaxBackground
        bgImage={heroImage}
        bgImageAlt="Pages Page"
        height="50vh"
        translate="-50%,-40%,0px"
      >
        <h1 data-sal="fade" data-sal-easing="ease">
          {title || "Pages"}
        </h1>
      </ParallaxBackground>
      <ContentContainer>
        <PagesWrapper>
          <Link to="/memory-path1-page/" style={{ boxShadow: `none` }}>
            <PagesContainer>
              <h3>{memoryPathPartOneTitle || "The Memory Path Part 1"}</h3>
              <p>{theMemoryGateDescription || "A book in the making"}</p>
            </PagesContainer>
          </Link>
          <Link
            to="/grande-hotel-fantasia-part1-page/"
            style={{ boxShadow: `none` }}
          >
            <PagesContainer>
              <h3>{grandHotelFantasiaTitle || "Grand Hotel Fantasia"}</h3>
              <p>{grandHotelFantasiaDescription || ""}</p>
            </PagesContainer>
          </Link>
          <Link to="/poetry/" style={{ boxShadow: `none` }}>
            <PagesContainer>
              <h3>{poetryTitle || "Poesia"}</h3>
              <p>{poetryDescription || "A collection of my poetry"}</p>
            </PagesContainer>
          </Link>
          <Link to="/thoughts/" style={{ boxShadow: `none` }}>
            <PagesContainer>
              <h3>{thoughtsTitle || "Thoughts"}</h3>
              <p>{thoughtsDescription || "A collection of my thoughts"}</p>
            </PagesContainer>
          </Link>
          <Link to="/publications/" style={{ boxShadow: `none` }}>
            <PagesContainer>
              <h3>{publicationsTitle || "Publications"}</h3>
              <p>
                {publicationsDescription || "A collection of my publications"}
              </p>
            </PagesContainer>
          </Link>
        </PagesWrapper>
      </ContentContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulPagesPage {
      edges {
        node {
          title
          memoryPathPartOneTitle
          memoryPathPartThreeDescription
          poetryDescription
          poetryTitle
          publicationsDescription
          publicationsTitle
          thoughtsTitle
          thoughtsDescription
          theMemoryGateDescription
          memoryPathPartThreeTitle
          grandHotelFantasiaDescription
          grandHotelFantasiaTitle
          heroImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`
